import React, { useEffect, useRef, useState } from "react"

import { GetWidth } from "../../gatsby-theme-tailwindui/utils/utils"
import * as styles from "./PDFLoader.module.css"
export default function PDFLoader(props) {
  const { pdf, pages } = props
  const [numPages, setNumPages] = useState(null)
  const [ready, setReady] = useState(false)
  const Module = useRef()

  useEffect(() => {
    async function dynamicImportModule() {
      if (typeof window !== "undefined") {
        const dynamicModule = await import("react-pdf/dist/esm/entry.webpack")
        Module.current = dynamicModule
        const url = `//cdn.jsdelivr.net/npm/pdfjs-dist@${dynamicModule.pdfjs.version}/build/pdf.worker.min.js`
        dynamicModule.pdfjs.GlobalWorkerOptions.workerSrc = url
        setReady(true)
      }
    }

    dynamicImportModule()
  }, [])

  const { Document, Page } = Module?.current || {
    Page: () => <div>Not ready</div>,
    Document: () => <div>Not Ready</div>,
  }
  if (!pdf) {
    return <div>Invalid PDF</div>
  }
  if (!ready) {
    return <div>Loading...</div>
  }

  return (
    <GetWidth>
      {(width) => (
        <Document
          className={styles.file}
          file={pdf}
          onLoadSuccess={({ numPages }) => setNumPages(numPages)}
        >
          {Array(!!pages && pages < numPages ? pages : numPages) // hardcoded pages otherwise automatic
            .fill(0)
            .map((x, i) => i + 1)
            .map((page) => (
              <Page pageNumber={page} width={width} />
            ))}
        </Document>
      )}
    </GetWidth>
  )
}
