import { PrinterIcon } from "@heroicons/react/solid"
import { graphql } from "gatsby"
import Layout from "gatsby-theme-tailwindui/src/components/layout"
import React, { useState } from "react"
import PDFLoader from "../../components/PDFLoader/PDFLoader"
import { useSiteMetadata } from "../../gatsby-theme-tailwindui/utils/use-site-metadata"

const PDFFile = (props) => {
  const { globalContactMenu, globalNavMenu } = useSiteMetadata()
  const post = props.pageContext
  const pdf = props.data.sanityPost.pdf?.pdfFile?.asset.url
  const menuItems = globalNavMenu
  const contactMenu = globalContactMenu
  return (
    <Layout navMenuItems={menuItems} contactMenuItems={contactMenu}>
      <div>
        <PDFLoader pdf={pdf} />
      </div>
      <div className="w-max mx-auto my-4">
        <a
          href={props.data.sanityPost?.pdf?.pdfFile?.asset.url}
          className="inline-flex items-center px-6 py-3 border border-transparent shadow-sm text-base font-medium rounded-md text-white bg-indigo-600 hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
          rel="noopener noreferrer"
          target="_blank"
        >
          Print This Page
          <PrinterIcon className="ml-3 -mr-1 h-5 w-5" aria-hidden="true" />
        </a>
      </div>
    </Layout>
  )
}

export default PDFFile

export const query = graphql`
  query($id: String!) {
    sanityPost(id: { eq: $id }) {
      id
      pdf {
        pdfFile {
          asset {
            url
          }
        }
        title
      }
    }
  }
`
