import React, { useEffect, useRef, useState } from 'react'

export function GetWidth({ children }) {
  const ref = useRef()
  const [width, setWidth] = useState(0)
  const size = useWindowSize()

  useEffect(() => {
    if (ref) {
      setWidth(ref.current.offsetWidth)
    }
  }, [ref, size.width])

  return (
    <div style={{ overflow: 'hidden' }} ref={ref}>
      {ref.current ? children(width) : ''}
    </div>
  )
}

export function useWindowSize() {
  // Initialize state with undefined width/height so server and client renders match
  // Learn more here: https://joshwcomeau.com/react/the-perils-of-rehydration/
  const [windowSize, setWindowSize] = useState({
    width: undefined,
    height: undefined,
  })

  useEffect(() => {
    // Handler to call on window resize
    function handleResize() {
      // Set window width/height to state
      setWindowSize({
        width: window.innerWidth,
        height: window.innerHeight,
      })
    }

    // Add event listener
    window.addEventListener('resize', handleResize)

    // Call handler right away so state gets updated with initial window size
    handleResize()

    // Remove event listener on cleanup
    return () => window.removeEventListener('resize', handleResize)
  }, []) // Empty array ensures that effect is only run on mount

  return windowSize
}
